.form-content {
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: none;
  width: 50%;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 0px 1px 4px gray;
  border-radius: 5px;

  .header {
    text-align: center;
    font-weight: bold;
    background-color: white;

    h5 {
      background-color: white;
      color: #3b5066;
    }
  }

  .body {
    background-color: white;

    .pin-required {
      background-color: white;
      text-align: center;
      margin-bottom: 16px;
    }

    form {
      width: 80%;
      margin: auto;
      background-color: white;
    }

    input {
      padding: 8px;
      width: 100%;
      margin: auto;
      border: 1px solid white;
      background-color: #dde4ee;
      color: #3b5066;
      border-radius: 5px;

      &::placeholder {
        color: #3b5066;
        opacity: .5;
      }

      &:hover {
        border: 1px solid  #0056b3;
      }
    }
  }

  .footer {
    margin: auto;
    background-color: white;
    justify-content: center;

    Button {
      width: 140px;
      margin: auto;
      display: block;
    }
  }
}
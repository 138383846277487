#loading-spinner-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0);

  &.dim-background {
    background-color: rgba(255, 255, 255, .5);
  }

  &.display-none {
    display: none;
  }

  .loading-spinner {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    background: none;

    > svg {
      background: none;
    }
  }
}
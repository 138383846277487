#admin {
  width: 70%;
  margin: auto;

  .admin-table {
    margin: auto;

    .line-item:hover {
      border-left: 8px solid #0056b3;
      cursor: pointer;
    }

    .line-item:nth-child( 2n ) .line-item-td {
      background-color: #dde4ee;
    }
    
    td,
    th {
      padding: 8px;
    }

    th,
    tr {
      border: 1px solid black;
    }
  
    tr:nth-child( 2n - 1 ) td {
      background-color: #dde4ee;
    }

    .right-align {
      text-align: right;
    }
  }
  /*.flex-container {
    display: flex;
    justify-content: space-between;
    list-style: none;
    flex-wrap: wrap;
    align-items: center;

    .customer {
      margin: 10px;

      .wrapper {
        background-color: white;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 0px 2px 2px gray;
        transition: box-shadow .3s ease;
        width: 300px;

        &:hover {
          box-shadow: 0px 2px 8px gray;
          cursor: pointer;
        }

        .bold {
          font-weight: bold;
        }

        .underline {
          text-decoration: underline;
        }

        .padding-top {
          padding-top: 8px;
        }

        .customer-data-key {
          background-color: white;
          width: 100px;
        }

        .customer-data-value {
          background-color: white;
          width: 200px;
          word-break: break-word;
        }

        .right-align {
          text-align: right;
        }
      }

      table {
        border-radius: 5px;
      }
    }
  }*/
}
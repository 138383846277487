@import url( 'https://fonts.googleapis.com/icon?family=Material+Icons' );

#header {
  font-family: 'Rubik', sans-serif;
  display: flex;
  justify-content: space-between;

  > div {
    line-height: 56px;
  }

  .spacer {
    display: inline-block;
    width: 25px;
    height: 56px;
    line-height: 56px;
    vertical-align: top;
    text-align: center;
  }
  
  a {
    color: white;
    text-decoration: none;
  }

  .btn {
    display: inline-block;
    color: white;
  }
}

#header-logo {
  padding: 18px 24px 19px 24px;
  height: 56px;

  > img {
    width: 109px;
    height: 24px;
  }
  
  &:hover,
  > img:hover {
    background-color: #dde4ee;

    img {
      background-color: #dde4ee;
    }
  }
}

#nav-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  list-style-type: none;
  margin: 0px;
  margin: auto;
  height: 30px;
  max-width: 1400px;

  > li {

    svg path {
      color: #5579a3;
    }

    &:hover,
    &:hover svg,
    .active,
    .active svg {
      cursor: pointer;

      path {
        color: #0056b3;
      }
    }
    
    > a {
      color: #3a4f66;
      text-decoration: none;
      float: right;
      margin-right: 24px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;

      &:hover {
        color: #26bff1;
      }
    }
  }
}

#billing {
  #metered-categories {
    .flex-container {
      max-width: 150px;
      display: flex;
      justify-content: space-between;
      margin: auto;
      align-items: center;
      padding: 10px;

      .spacer {
        border: solid 1px #5579a3;
        display: inline-block;
        height: 5px;
        transition: height .2s ease;
      }

      &:hover {
        .spacer {
          border-color: #0056b3;
          height: 70px;
        }
      }
    }

    .flex-subcontainer {
      display: flex;
      justify-content: space-between;
      margin: auto;
    }

    .right-align {
      text-align: right;
    }
  }

  .react-date-picker-container {
      width: 70%;
      margin: auto;

    .react-date-picker {
      color: #3b5066;

    .react-date-picker__wrapper,
    .react-date-picker__wrapper * {
      border: none;

      .react-date-picker__inputGroup__input,
      .react-date-picker__button {
        &::placeholder {
          opacity: .3;
        }

        svg {
          stroke: #3b5066;
        }

        &:hover,
        &:hover svg {
          background-color: #dde4ee;
          border-radius: 4px;
        }
      }
    }

    .react-calendar {
      border: 1px solid black;

      .react-calendar__navigation {
        background-color: white;

        * {
          font-weight: bold;

          &:hover {
            background-color: #dde4ee;
          }
        }

        .react-calendar__navigation__label {
          background-color: white;

          .react-calendar__navigation__label__labelText {
            background-color: white;
          }

          &:hover {
            background-color: #dde4ee;

            .react-calendar__navigation__label__labelText {
              background-color: #dde4ee;
            }
          }
        }
      }
      
      .react-calendar__month-view__weekdays * {
        background-color: white;
        text-decoration: underline;
      }

      .react-calendar__tile,
      .react-calendar__tile abbr {
        background-color: white;

        &:hover,
        &:hover abbr {
          background-color: #dde4ee;
        }
      }

      .react-calendar__tile--active,
      .react-calendar__tile--active abbr {
        background-color: #0077ff;
        color: white;

        &:hover abbr {
          color: #3b5066;
        }
      }
      
      .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd,
      .react-calendar__tile--rangeStart abbr,
      .react-calendar__tile--rangeEnd abbr {
        background-color: #0056b3;
        color: white;

        &:hover abbr {
          color: #3b5066;
        }
      }
    }
    }
  }

  .billing-table {
    width: 70%;
    margin: auto;
    
    .th_:first-child {
      border: none;
    }

    .th_ {
      text-align: center;
    }

    .line-item:hover {
      cursor: pointer;
    }

    .line-item:nth-child( 2n ) .line-item-td {
      background-color: #dde4ee;
    }
    
    td,
    th {
      padding: 8px;
    }

    th {
      border-bottom: solid 1px;
    }
  }

  h1 {
    text-align: center;
    width: 70%;
    margin: auto;
  }

  .right-align {
    text-align: right;
  }
}

#totals-table {
  width: 70%;
  margin: auto;

  td,
  th {
    padding: 8px;
  }

  .total-cost {
    border: #3b5066 solid 1px;
    padding: 4px;
    position: relative;
    margin: auto;
  }

  .right-align {
    text-align: right;
  }
}
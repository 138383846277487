#activity {
  .card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .card {
      display: flex;
      flex-direction: row;
      margin-top: auto;
      margin-bottom: auto;

      &:hover {
        svg {
          background-color: #0056b3;
        }

        .card-title-container {
          color: #0056b3;
        }
      }

      .card-title-container {
        font-size: 1.4em;
        line-height: 64px;
        width: 100%;
        transition: color .3s ease;
      }

      svg {
        background-color: #5579a3;//#15BEF0;//white;
        transition: background-color .3s ease;
      }
    }
  }
}
.month-year-date-picker {
  width: 70%;
  margin: auto;

  .month-select-container {
    margin-bottom: 4px;
  }

  .month-select,
  .year-select {
    width: 120px;
  }
}
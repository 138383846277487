#user-impersonation-info {
  width: fit-content;
  padding: 16px;
  margin: auto;

  .info {

    .customer-name,
    .email,
    .impersonating {
      text-align: center;
    }
  }
}
@import url( 'https://fonts.googleapis.com/css?family=Rubik:400&display=swap' );

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #f5f7fa;
  color: #3b5066;
}

html {
  position: relative;
  height: 100vh;
}

body {
  font-family: 'Rubik', sans-serif;
}
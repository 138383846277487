#details {
  > * {
    width: 70%;
    margin: auto;
  }

  .endpoint-modal-container {
    position: relative;
    width: fit-content;
    height: fit-content;
    margin-left: 15%;
  }

  .alert {
    margin: 15px auto 0px auto;
    text-align: center;

    span {
      background-color: #cce5ff;
    }
  }

  > .title {
    color: #3b5066;
    text-align: left;
  }

  .btn,
  .btn-secondary {
    //
  }
}

.details-table {
  margin: auto;
  width: 100%;

  table,
  th,
  tr {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 10px;
  }

  td {
    word-wrap: break-word;
    max-width: 0;
  }
  
  tr:nth-child( 2n - 1 ) td {
    background-color: #dde4ee;
  }

  tbody tr:hover {
    border-left: 8px solid #0056b3;
    cursor: pointer;
  }

  .col-w120 {
    width: 120px;
  }
  
  .col-w160 {
    width: 160px;
  }

  .col-w220 {
    width: 220px;
  }
  
  .col-w350 {
    width: 350px;
  }
}

.chart-container {
  height: 50vh;
  min-height: 270px;
}

.details-list {
  list-style-type: none;
}

.details-container {
  transition: box-shadow .3s ease, border-radius .3s .2s ease;
  border-radius: 5px;
  margin: 2px 2px 10px 2px;
  padding: 5px;

  &:hover {
    box-shadow: 0px 2px 4px gray;
    border-radius: 17px;
  }
}

.paging-container {
  display: flex;
  flex-direction: row-reverse;
  height: 36px;

  .pagination {
    background-color: #dde4ee;

    .page-item {
      button {
        background-color: #f5f7fa;
        color: #3b5066;
        margin: 0px 2px 0px 2px;
        font-weight: bold;
        border-radius: 18px;
        border: none;
      }
    }

    .active {
      button {
        z-index: 0;
        background-color: #e4e9f1;
        width: 85px;
        cursor: default;
      }
    }  
  }
  
  span,
  input {
    height: 36px;
    padding: 4px;
    margin: -10px 0px 0px 0px;
  }

  input {
    background-color: #f5f7fa;
    border: none;
    border-radius: 4px;
    width: 50px;
    text-align: center;
    
    &:hover {
      background-color: #dde4ee;
      cursor: pointer;
    }

    &:focus {
      cursor: text;
      outline: none;
      border: 3px solid #b1d4f1;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield; //firefox
  }

  .go-button {
    background-color: #0077ff;
    color: #dde4ee;
    border: none;
    border-radius: 4px;
    width: 40px;
    margin: -10px 0px 10px 0px;

    &:hover {
      background-color: #0056b3;
    }
  }
}

#button-container {
  display: flex;
  flex-direction: row-reverse;

  .spacing-left {
    border-left: 5px solid transparent;
    margin: -10px 0px 0px 0px;
  }

  .spacing-right {
    border-right: 5px solid transparent;
    margin: -10px 0px 0px 0px;
  }
}
#summary {
  > * {
    width: 70%;
    margin: auto;
  }
  
  h3 {
    color: #3b5066;
    text-align: left;
  }
}

.summary-table {
  margin: auto;
  width: 100%;

  table,
  th,
  tr {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 10px;
  }

  td {
    word-wrap: break-word;
    max-width: 0;  
  }
  
  tr:nth-child( 2n - 1 ) td {
    background-color: #dde4ee;
  }

  tbody tr:hover {
    border-left: 8px solid #0056b3;
    cursor: pointer;
  }

  .col-w120 {
    width: 120px;
  }
  
  .col-w160 {
    width: 160px;
  }

  .col-w220 {
    width: 220px;
  }
}

.chart-container {
  height: 50vh;
  min-height: 270px;
}

.summary-list {
  list-style-type: none;
}

.summary-container {
  transition: box-shadow .3s ease, border-radius .3s .2s ease;
  border-radius: 5px;
  margin: 2px 2px 10px 2px;
  padding: 5px;

  &:hover {
    box-shadow: 0px 2px 4px gray;
    border-radius: 17px;
  }
}
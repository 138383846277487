#endpoint-modal {
  position: fixed;
  background-color: rgba( 0, 0, 0, .4 );
  height: 100%;
  width: 100% !important;
  z-index: 1;
  top: 0px;
  left: 0px;

  .modal-content {
    position: relative;
    background-color: #f5f7fa;
    margin: 12% auto;
    border: 1px solid black;
    max-width: 80%;
    min-width: 300px;
    max-height: 88%;

    .header {
      text-align: center;

      .close {
        position: absolute;
        top: 12px;
        right: 16px;
        padding: 8px;
        border-radius: 20px;
        color: black;

        &:hover {
          background-color: #dde4ee;
          color: #0056b3;
          cursor: pointer;
        }
      }

      .title {
        font-weight: bold;
        text-align: center;
      }
    }

    .body {
      overflow: auto;
      
      .endpoints {
        column-rule-width: 1px;
        column-rule-style: solid;
        list-style: none;

        .endpoint > div {
          padding: 8px 0px 8px 0px;
          margin: 12px 0px 12px 0px;
          border-radius: 0px 8px 8px 0px;

          &:hover {
            background-color: #dde4ee;
            color: #0056b3;
            cursor: pointer;
            border-left: 5px solid #0056b3;
          }
        }

        .active > div {
          background-color: #dde4ee;
          color: #0056b3;
          cursor: pointer;
          border-left: 5px solid #0056b3;
        }
      }
    }

    .footer {
      margin: auto;

      Button {
        width: 200px;
      }
    }
  }
}

@media only screen and ( max-width: 599px ) {
  .endpoints {
    column-count: 1;
  }
}

@media only screen and ( max-width: 898px ) and ( min-width: 600px ) {
  .endpoints {
    column-count: 2;
  }
}

@media only screen and ( max-width: 1124px ) and ( min-width: 899px ) {
  .endpoints {
    column-count: 3;
  }
}

@media only screen and ( min-width: 1125px ) {
  .endpoints {
    column-count: 4;
  }
}
h2 {
  text-align: left;
}

#restful > * {
  width: 70%;
  margin: auto;
}

#api-notes {
  width: 60%;
  margin: auto;
}

#client-info-container {
  display: flex;
  flex-direction: column;

  * {
    margin: auto;
  }

  div {
    text-align: center;
    width: 200px;
  }

  button,
  .key-label {
    width: 160px;
    text-align: center;
    padding: 6px 12px;
  }

  .key-label {
    display: inline;
    cursor: pointer;
  }

  .squeeze-left {
    position: relative;
    animation: squeezeLeftAnimation ease 2s; 
    animation-iteration-count: infinite; 
    animation-fill-mode: forwards;
    display: inline;
    left: 0px;
  }

  .squeeze-right {
    position: relative;
    animation: squeezeRightAnimation ease 2s; 
    animation-iteration-count: infinite; 
    animation-fill-mode: forwards;
    display: inline;
    right: 0px;
  }
}

.client-key {
  border: none;
  outline: none;
  border-width: 0px;
  padding: 4px 8px;
  width: 100%;
  text-align: center;
}

#endpoints {
  position: relative;
  column-width: 300px;

  li {
    list-style-type: none;
    
    > div {
      padding: 5px;
      margin-top: 6px;
      margin-bottom: 6px;
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
      transition: box-shadow .3s ease;//, border-radius .3s .2s ease;
      border-radius: 17px;

      &:hover {
        box-shadow: 0px 2px 4px gray;
        cursor: pointer;
      }
    }
  }
}

@media only screen and ( max-width: 600px ) {
  #endpoints {
    column-count: 1;
  }
}

@media only screen and ( max-width: 1124px ) and ( min-width: 601px ) {
  #endpoints {
    column-count: 2;
  }
}

@media only screen and ( min-width: 1125px ) {
  #endpoints {
    column-count: 3;
  }
}

@keyframes squeezeLeftAnimation {
    0% {
      left: 0px;
    }
    50% {
      left: 10px;
    }
    100% {
      left: 0px;
    }
}

@keyframes squeezeRightAnimation { 
    0% {
      right: 0px;
    }
    50% {
      right: 10px;
    }
    100% {
      right: 0px;
    }
}
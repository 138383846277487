#footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  color: #3d5066;

  > ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  a {
    color: #5579a3;
    text-decoration: none;
  }

  a:hover {
    color: #0056b3;
  }

  svg {
    height: 24px;
    width: 24px;
  }

  > * {
    width: 80%;
    margin: auto;
  }
}

#socials {
  * {
    color: #5579a3;
    text-decoration: none;
  }

  li:hover {
    cursor: pointer;
    background-color: #dde4ee;
    border-radius: 5px;

    * {
      background-color: #dde4ee;
      color: #0056b3;
    }
  }

  > li {
    padding: 1px 5px 1px 5px;
  }
}
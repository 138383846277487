@import url( 'https://fonts.googleapis.com/icon?family=Material+Icons' );

#activity-header {
  background-color: #5579a3;
  width: 100%;
  display: flex;
  justify-content: space-between;

  * {
    border: none;
  }

  .header-subcontainer {
    height: 46px;
    background-color: #5579a3;
  }

  .header-tabs {
    background-color: #5579a3;

    .activity-tab {
      background-color: #5579a3;
      border-radius: 0px;
      
      &:hover,
      &:hover svg {
        background-color: #0056b3;
      }

      svg {
        background-color: #5579a3;

        path {
          color: white;
        }
      }
    }

    .active-tab {
      background-color: #0056b3;

      svg {
        background-color: #0056b3;
      }
    }
  }

  .header-title {
    display: inline-block;
    font-size: 28px;
    padding: 0px 25px 0px 25px;
    line-height: 46px;
    background-color: #5579a3;
    color: white;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      background-color: #0056b3;
    }
  }

  .spacer {
    display: inline-block;
    width: 30px;
    height: 46px;
    background-color: #5579a3;
    line-height: 46px;
    vertical-align: top;
    text-align: center;
  }

  .plot-by-picker {
    display: inline-block;

    .btn-group {
      height: 46px;
      top: -5px;

      .btn {
        border-radius: 0px;

        &:active, 
        &:focus {
          background-color: #0056b3;
        }
      }

      .dropdown-menu {
        color: #3b5066;
        border: 1px solid black;

        button:hover {
          background-color: #dde4ee;
          color: #0056b3
        }

        .active-metric {
          background-color: #dde4ee;
          color: #0056b3;
        }
      }
    }
  }

  .react-date-picker {
    height: 46px;
    top: -4px;
    
    .react-date-picker__range-divider {
      line-height: 46px;
    }

    .react-date-picker__clear-button {
      display: none;
    }

    .react-date-picker__wrapper,
    .react-date-picker__wrapper * {
      background-color: #5579a3;
      color: white;

      .react-date-picker__inputGroup__input,
      .react-date-picker__button {
        &::placeholder {
          color: white;
          opacity: .3;
        }

        svg {
          stroke: white;
        }

        &:hover,
        &:hover svg {
          background-color: #0056b3;
        }
      }
    }

    .react-calendar {
      border: 1px solid black;

      .react-calendar__navigation {
        background-color: white;

        * {
          font-weight: bold;

          &:hover {
            background-color: #dde4ee;
          }
        }

        .react-calendar__navigation__label {
          background-color: white;

          .react-calendar__navigation__label__labelText {
            background-color: white;
          }

          &:hover {
            background-color: #dde4ee;

            .react-calendar__navigation__label__labelText {
              background-color: #dde4ee;
            }
          }
        }
      }
      
      .react-calendar__month-view__weekdays * {
        background-color: white;
        text-decoration: underline;
      }

      .react-calendar__tile,
      .react-calendar__tile abbr {
        background-color: white;

        &:hover,
        &:hover abbr {
          background-color: #dde4ee;
        }
      }

      .react-calendar__tile--active,
      .react-calendar__tile--active abbr {
        background-color: #0077ff;
        color: white;

        &:hover abbr {
          color: #3b5066;
        }
      }
      
      .react-calendar__tile--rangeStart,
      .react-calendar__tile--rangeEnd,
      .react-calendar__tile--rangeStart abbr,
      .react-calendar__tile--rangeEnd abbr {
        background-color: #0056b3;
        color: white;

        &:hover abbr {
          color: #3b5066;
        }
      }
    }
  }
}
#home {
  color: #3b5066;

  > * {
    width: 80%;
    margin: auto;
  }
}

#banner {
  width: 100%;
  overflow: hidden;
}

#card-contain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

.card {
  margin: 20px;
  justify-content: space-around;
  max-width: 240px;
  border: 1px solid rgba( 0, 0, 0, .1 );
  transition: box-shadow .3s ease;

  &:hover {
    box-shadow:  0px 3px 6px 6px rgba( 75, 113, 148, .2 );
    transition: box-shadow .3s ease;
    cursor: pointer;

    .card-title {
      color: #0056b3;
    }
  }

  > * {
    text-align: center;
    justify-content: center;
  }
}

.card-title {
  font-size: 1.4em;
  transition: color .3s ease;
}

.btn {
  background-color: #5579a3;
  border-color: #5579a3;

  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
}

h1,
h2,
h3 {
  text-align: center;
}

@media only screen and ( max-width: 866px ) {
  #banner * {
    width: 866px;
  }
  
  .card {
    flex: 0 1 100%;
  }
}

@media only screen and ( max-width: 1726px ) and ( min-width: 867px ) {
  #banner * {
    width: 1726px;
  }
  
  .card {
    flex: 0 1 50%;
  }
}

@media only screen and ( min-width: 1727px ) {
  #banner * {
    width: 2048px;
    display: block;
    margin: auto;
  }
  
  .card {
    flex: 0 1 25%;
  }
}